<template>
  <div>
    <general-config />
    <other-config />
  </div>
</template>

<script>
import GeneralConfig from './GeneralConfig.vue'
import OtherConfig from './OtherConfig.vue'

export default {
  components: {
    GeneralConfig,
    OtherConfig,
  },
}
</script>

<style>

</style>
