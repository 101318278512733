<template>
  <div>
    <h6 class="align-self-center">
      General Configuration
    </h6>
    <b-card
      no-body
      text-variant="white"
      class="overflow-hidden"
    >
      <b-card-body
        v-for="(item, index) in filter"
        :key="`filter-${index}`"
      >
        <div class="d-flex justify-content-between align-items-center">
          <b-media
            no-body
            :class="{ 'in-active': !item.active }"
          >
            <feather-icon
              size="24"
              class="mr-1"
              :icon="item.icon"
            />
            <h6 class="align-self-center my-auto">
              {{ item.title }}
            </h6>
          </b-media>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="item.active"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      filter: [
        {
          title: 'Config 1',
          icon: 'TruckIcon',
          active: true,
        },
        {
          title: 'Config 2',
          icon: 'PhoneCallIcon',
          active: false,
        },
        {
          title: 'Config 3',
          icon: 'ZapIcon',
          active: false,
        },
        {
          title: 'Config 4',
          icon: 'TruckIcon',
          active: true,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.card .card-header,
.card .card-body {
  padding: 1rem;
}
.media.in-active {
  opacity: 0.5;
}
</style>
