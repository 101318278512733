<template>
  <div>
    <h6 class="align-self-center">
      Other Configuration
    </h6>
    <b-card
      no-body
      text-variant="white"
      class="overflow-hidden"
    >
      <b-card-body
        v-for="(item, index) in filter"
        :key="`filter-${index}`"
      >
        <div class="d-flex justify-content-between align-items-center">
          <b-media
            no-body
            :class="{ 'in-active': !item.active }"
          >
            <feather-icon
              size="24"
              class="mr-1"
              :icon="item.icon"
            />
            <h6 class="align-self-center my-auto">
              {{ item.title }}
            </h6>
          </b-media>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="item.active"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </b-card-body>
      <b-card-body>
        <b-form-select
          v-model="selected1"
          :options="options1"
        />

        <b-form-select
          v-model="selected2"
          :options="options2"
          class="mt-1"
        />
        <h6 class="text-sm mt-1">Sample notes here</h6>

        <b-button
          block
          variant="outline-light"
          class="mt-3 text-left d-flex justify-content-between"
        >
          <span>Button</span>
          <feather-icon
            icon="CheckIcon"
          />
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      filter: [
        {
          title: 'Config 1',
          icon: 'TruckIcon',
          active: true,
        },
        {
          title: 'Config 2',
          icon: 'PhoneCallIcon',
          active: false,
        },
      ],
      selected1: null,
      options1: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      selected2: null,
      options2: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.card .card-header,
.card .card-body {
  padding: 1rem;
}
.media.in-active {
  opacity: 0.5;
}
</style>
